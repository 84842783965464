import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { Link, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import Hexes from '../components/Hexes.js';
import Layout from '../components/Layout';
import blueSmokeImg from '../../src/img/blue-smoke-bg.jpg';
import styled from 'styled-components';
import useScrollAnimation from '../utils/use-scrollanimation2';
import { useSpring, animated as a } from 'react-spring';
import { find as _find } from 'lodash';
import AnimHexgroup from '../components/AnimHexgroup.js';
import interpolateAndClamp from '../utils/interpolate-and-clamp';
import Hls from 'hls.js';

const StyledContainer = styled.div`
  @keyframes fadeout {
    0% {
      opacity: 1;
    }
    66% {
      opacity: 0.5;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes ffadein {
    0% {
      opacity: 0;
      width: 40px;
    }
    66% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
      width: 65%;
      max-width: 1400px;
    }
  }

  @media (max-width: 900px) {
    @keyframes ffadein {
      0% {
        opacity: 0;
        width: 40px;
      }
      66% {
        opacity: 0.5;
      }
      100% {
        opacity: 1;
        width: 59%;
      }
    }
  }

  /* background: #fcfdff; */
  background: #fff;
  overflow-x: hidden;
  @media only screen and (min-width: 900px) {
    margin-top: 110px;
  }
  .introPanel {
    position: relative;
    width: 100%;
    height: 100vh;
    top: 0;
    background: #fff;
    z-index: 2;

    /* box-shadow: #6ca9dc87 0px 3px 13px 0px; */
  }
  .bottom-line {
    display: block;
    width: 10px;
    height: 3px;
    background-color: #4fa6ee;
    position: absolute;
    bottom: 0px;
    left: calc(50% - 5px);
  }
  .flexCenter {
    display: flex;
    position: absolute;
    height: 100vh;
    width: 100%;
    align-items: center;
    justify-content: center;
    z-index: 3;
  }
  .what-we-do {
    font-family: 'Oswald';
    color: #1a89e3;
    /* color: rgba(0, 0, 0, 0.8); */
    font-size: 6rem;
    text-align: center;
    font-weight: 700;
    line-height: 1.1;
    animation: fadeout 1s cubic-bezier(0.55, 0.09, 0.68, 0.53) forwards;
    animation-delay: 1s;
  }

  .hex-group-container {
    position: fixed;
    -webkit-mask-position-y: 100%;
    @media screen and (min-width: 1300px) {
      transform: scale(1.2);
      transform-origin: top center;
    }
  }

  .hex-large .symbol {
    color: #6caadd;
  }
  .hex-large .label {
    color: #000;
  }
  .thesvg {
    width: 40px;
    opacity: 0;
    fill: #188ae2;
    animation: ffadein 1s cubic-bezier(0.55, 0.09, 0.68, 0.53) forwards;
    animation-delay: 2.2s;
    svg {
      display: block;
      margin: 0 auto;
    }
  }
  .thesvg.active {
    width: 80%;
    opacity: 1;
  }
  .slide {
    height: 100vh;
    position: relative;
    background: transparent;
    color: black;
    strong {
      color: #009fce;
      font-weight: 500;
    }
    .gatsby-image-wrapper {
      width: 100%;
      height: 100vh;
    }
    .message {
      z-index: 5;
      color: black;
      text-align: left;
      max-width: 900px;
      width: 100%;
      margin: 0 auto;
      background: rgba(255, 255, 255, 0.8);
      box-shadow: rgba(255, 255, 255, 0.8) 0px 0px 20px 12px;
      font-family: 'Roboto';
      font-weight: 300;
      letter-spacing: 1.3px;
      font-size: 1.25rem;
      line-height: 1.9;
      padding: 0 30px;
      box-sizing: border-box;
      @media (max-width: 600px) {
        font-size: 1rem;
      }
    }
    .theresults {
      font-size: 75px;
      text-transform: uppercase;
      font-family: 'Oswald';
      p {
        margin-bottom: 40px;
      }
    }

    &.slide-1 {
      margin-top: 60vh;
    }
  }
  .slide-1 h1 {
    color: #009fce;
  }
  /* path {
    fill: black;
  } */

  @media (max-width: 900px) {
    .introPanel {
      margin-top: -28vw;
      height: 189vw;
    }
    .flexCenter {
      height: 189vw;
    }
    .slide {
      height: auto;
      z-index: 4;
    }
    .slide.slide-1 {
      margin-top: 0;
    }
  }
  @media (max-width: 600px) {
    .what-we-do {
      font-size: 4.5rem;
    }
  }
  @media (max-width: 400px) {
    .what-we-do {
      font-size: 3rem;
    }
  }

  .slide4 {
    background-image: url(${blueSmokeImg});
    padding-bottom: 100px;
    background-size: cover;
    h2 {
      margin: 0 auto;
      padding: 98px 0 60px 0;
      text-transform: uppercase;
      font-family: 'Oswald';
      font-size: 4rem;
      line-height: 4rem;
      text-align: center;
      font-weight: 400;
      transition: all 200ms linear;
      @media (max-width: 600px) {
        font-size: 3rem;
      }
    }
  }

  .service-grid {
    display: grid;
    grid-template-columns: 327fr 160fr 166fr 415fr 174fr 184fr 296fr;
    grid-template-rows: 251fr 195fr 251fr 209fr 276fr 67fr 11.5%;

    grid-column-gap: 0px;
    grid-row-gap: 0px;
    width: 84vw;
    max-width: 1720px;
    height: 68.84vw;
    position: relative;
    margin: 0 auto;

    a,
    a:visited,
    a:active {
      color: #fff;
    }

    .label {
      position: absolute;
      z-index: 3;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-family: 'Oswald';
      letter-spacing: 3.3px;
      font-size: 28px;
      text-transform: uppercase;
      text-align: center;
      width: 100%;
    }

    & > div.item {
      position: relative;
    }
    & > div.item .gatsby-image-wrapper {
      position: relative;
      width: 100%;
      height: 100%;
    }

    & > div.item::after {
      content: '';
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.55);
      pointer-events: none;
    }

    .div1 {
      grid-area: 1 / 1 / 2 / 4;
    }
    .div2 {
      grid-area: 1 / 4 / 3 / 6;
    }
    .div3 {
      grid-area: 1 / 6 / 3 / 8;
    }
    .div4 {
      grid-area: 2 / 1 / 4 / 2;
    }
    .div5 {
      grid-area: 2 / 2 / 4 / 4;
    }
    .div6 {
      grid-area: 4 / 1 / 5 / 4;
    }
    .div7 {
      grid-area: 3 / 4 / 5 / 5;
    }
    .div8 {
      grid-area: 3 / 5 / 5 / 7;
    }
    .div9 {
      grid-area: 3 / 7 / 5 / 8;
    }
    .div10 {
      grid-area: 5 / 1 / 8 / 3;
    }
    .div11 {
      grid-area: 5 / 3 / 6 / 6;
    }
    .div12 {
      grid-area: 5 / 6 / 8 / 8;
    }
    .div13 {
      grid-area: 6 / 3 / 8 / 6;
    }
  }

  @media screen and (max-width: 1150px) {
    .service-grid {
      display: grid;
      grid-template-columns: 324fr 139fr 124fr 57fr 85fr 303fr;
      grid-template-rows: 250fr 195fr 250fr 211fr 440fr 194fr 211fr 330fr 14.37vw;

      grid-column-gap: 0px;
      grid-row-gap: 0px;
      width: 92vw;
      height: 198vw;

      .div1 {
        grid-area: 1 / 1 / 2 / 5;
      }

      .div2 {
        grid-area: 5 / 1 / 6 / 4;
      }

      .div3 {
        grid-area: 5 / 4 / 6 / 7;
      }

      .div4 {
        grid-area: 2 / 1 / 4 / 2;
      }

      .div5 {
        grid-area: 2 / 2 / 4 / 5;
      }

      .div6 {
        grid-area: 4 / 1 / 5 / 5;
      }

      .div7 {
        grid-area: 1 / 5 / 3 / 7;
      }

      .div8 {
        grid-area: 3 / 5 / 5 / 7;
      }

      .div9 {
        grid-area: 6 / 6 / 8 / 7;
      }

      .div10 {
        grid-area: 8 / 1 / 10 / 4;
      }

      .div11 {
        grid-area: 6 / 1 / 7 / 6;
      }

      .div12 {
        grid-area: 8 / 4 / 10 / 7;
      }

      .div13 {
        grid-area: 7 / 1 / 8 / 6;
      }
    }
  }
  @media screen and (max-width: 650px) {
    .service-grid {
      background: rgba(0, 0, 0, 0.7);
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 295fr 568fr 268fr 450fr 502fr 600fr 215fr 580fr 380fr 410fr 200fr;

      grid-column-gap: 0px;
      grid-row-gap: 2px;
      width: 95vw;
      height: auto;

      .div1 {
        grid-area: 1 / 1 / 2 / 3;
      }

      .div2 {
        grid-area: 4 / 1 / 5 / 3;
      }

      .div3 {
        grid-area: 5 / 1 / 6 / 3;
      }

      .div4 {
        grid-area: 2 / 1 / 3 / 2;
      }

      .div5 {
        grid-area: 2 / 2 / 3 / 3;
      }

      .div6 {
        grid-area: 3 / 1 / 4 / 3;
      }

      .div7 {
        grid-area: 6 / 1 / 7 / 3;
      }

      .div8 {
        grid-area: 8 / 1 / 9 / 2;
      }

      .div9 {
        grid-area: 8 / 2 / 9 / 3;
      }

      .div10 {
        grid-area: 9 / 1 / 10 / 3;
      }

      .div11 {
        grid-area: 7 / 1 / 8 / 3;
      }

      .div12 {
        grid-area: 10 / 1 / 11 / 3;
      }

      .div13 {
        grid-area: 11 / 1 / 12 / 3;
      }
    }
  }
`;

const StyledHexGroup = styled.div`
  width: 1200px;
  height: 430px;
  top: calc(50% - 430px / 2);
  left: calc(50% - 600px);
  box-sizing: content-box;
  position: fixed;
  .svgwrap {
    position: absolute;
    width: 121px;
    opacity: 1;
  }
  .svgwrap.hex-large {
    transform: rotate(90deg);
  }
  @media screen and (min-width: 1300px) {
    transform: scale(1.2);
    transform-origin: top center;
  }
`;

const FirstSVG = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120.0435 9.9907">
      <title>The Element Approach</title>
      <g>
        <path d="M1.4634,1.7271H0V.1318H5.0732V1.7271H3.6221v8.12H1.4634Z" />
        <path d="M5.9634.1318H8.11V4.0659h1.499V.1318h2.1592V9.8467H9.6094V5.6011H8.11V9.8467H5.9634Z" />
        <path d="M13.2476.1318h4.3652V1.5952H15.394V4.0059h1.6914V5.4932H15.394V8.3955h2.2432V9.8467h-4.39Z" />
        <path d="M21.6836.1318h4.3657V1.5952H23.83V4.0059h1.6914V5.4932H23.83V8.3955h2.2431V9.8467h-4.39Z" />
        <path d="M27.0474.1318h2.1464V8.3955h2.231V9.8467H27.0474Z" />
        <path d="M32.3633.1318H36.729V1.5952H34.51V4.0059h1.6914V5.4932H34.51V8.3955h2.2431V9.8467h-4.39Z" />
        <path d="M37.8589.1318h2.3027l1.0674,5.649L42.38.1318h2.2187l.168,9.7149H43.2437l-.18-6.4644L41.9365,9.8467h-1.355l-1.2-6.5122-.1435,6.5122H37.6909Z" />
        <path d="M46.1753.1318h4.3652V1.5952H48.3218V4.0059h1.6914V5.4932H48.3218V8.3955h2.2431V9.8467h-4.39Z" />
        <path d="M51.5391.1318H53.05L55.041,4.81V.1318h1.7749V9.8467H55.3647L53.374,4.81V9.8467H51.5391Z" />
        <path d="M59.1465,1.7271H57.6831V.1318h5.0732V1.7271H61.3052v8.12H59.1465Z" />
        <path d="M68.05.1318h2.4468l1.811,9.7149h-1.979l-.3237-2.0508H68.5776l-.3359,2.0508H66.2148Zm1.751,6.3809-.5156-3.79-.5157,3.79Z" />
        <path d="M73.2944.1318h3.2622c1.7388,0,2.5547,1.02,2.5547,2.8189,0,2.2427-1.2353,2.77-2.8066,2.77h-.8638V9.8467H73.2944Zm2.8662,4.09c.7915,0,.9356-.42.9356-1.3071,0-.7315-.084-1.2715-.9473-1.2715h-.708V4.2217Z" />
        <path d="M80.1465.1318h3.2622c1.7388,0,2.5547,1.02,2.5547,2.8189,0,2.2427-1.2354,2.77-2.8067,2.77H82.293V9.8467H80.1465Zm2.8662,4.09c.7915,0,.9355-.42.9355-1.3071,0-.7315-.0839-1.2715-.9472-1.2715h-.708V4.2217Z" />
        <path d="M86.9985.1318h2.6744c1.955,0,3.226.5279,3.226,2.5669,0,1.1992-.2275,1.9908-1.187,2.4463L93.043,9.8467H90.8242L89.7568,5.4932H89.145V9.8467H86.9985Zm2.7466,4.126c.8994,0,1.1631-.5039,1.1631-1.3672,0-.8276-.252-1.3193-1.0674-1.3193H89.145V4.2578Z" />
        <path d="M93.9707,6.7886v-3.61C93.9707,1.1753,94.7021,0,96.8848,0c2.1948,0,2.9384,1.1753,2.9384,3.1782v3.61c0,2.0029-.7436,3.2021-2.9384,3.2021C94.7021,9.9907,93.9707,8.7915,93.9707,6.7886Zm3.6577.54V2.6509c0-.5762-.0718-1.14-.7436-1.14-.6358,0-.7193.5635-.7193,1.14V7.3281c0,.5518.0479,1.1392.7193,1.1392S97.6284,7.88,97.6284,7.3281Z" />
        <path d="M102.3579.1318h2.4468l1.811,9.7149h-1.979l-.3237-2.0508h-1.4273L102.55,9.8467h-2.0269Zm1.751,6.3809-.5156-3.79-.5157,3.79Z" />
        <path d="M107.3149,6.7524V3.2505c0-2.0151.6954-3.25,2.9141-3.25,2.147,0,2.7944,1.1514,2.7944,2.8066V3.646h-2.0986V2.6748c0-.564-.0122-1.1636-.6958-1.1636s-.7436.6118-.7436,1.1992V7.28c0,.66.1079,1.187.7436,1.187.6719,0,.6958-.6.6958-1.187v-1.02h2.0986V7.1c0,1.6553-.6235,2.8906-2.7944,2.8906C108.01,9.9907,107.3149,8.7192,107.3149,6.7524Z" />
        <path d="M114.2383.1318h2.147V4.0659h1.499V.1318h2.1592V9.8467h-2.1592V5.6011h-1.499V9.8467h-2.147Z" />
      </g>
    </svg>
  );
};

const SecondSVG = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 137.8271 9.9907">
      <title>One Integrated Approach</title>
      <g>
        <path d="M0,6.7886v-3.61C0,1.1753.7314,0,2.9141,0,5.1089,0,5.8525,1.1753,5.8525,3.1782v3.61c0,2.0029-.7436,3.2021-2.9384,3.2021C.7314,9.9907,0,8.7915,0,6.7886Zm3.6577.54V2.6509c0-.5762-.0718-1.14-.7436-1.14-.6358,0-.72.5635-.72,1.14V7.3281c0,.5518.0484,1.1392.72,1.1392S3.6577,7.88,3.6577,7.3281Z" />
        <path d="M7.1641.1318H8.6753L10.666,4.81V.1318h1.7749V9.8467H10.99L8.999,4.81V9.8467H7.1641Z" />
        <path d="M13.8955.1318h4.3652V1.5952H16.042V4.0059h1.6914V5.4932H16.042V8.3955h2.2432V9.8467h-4.39Z" />
        <path d="M22.3433.1318H24.49V9.8467H22.3433Z" />
        <path d="M25.9434.1318h1.5112L29.4453,4.81V.1318H31.22V9.8467H29.769L27.7783,4.81V9.8467H25.9434Z" />
        <path d="M33.5508,1.7271H32.0874V.1318h5.0732V1.7271H35.71v8.12H33.5508Z" />
        <path d="M38.0151.1318h4.3658V1.5952H40.1616V4.0059H41.853V5.4932H40.1616V8.3955h2.2432V9.8467h-4.39Z" />
        <path d="M43.2471,6.6323V3.37c0-2.063.6953-3.37,2.914-3.37,2.147,0,2.8428,1.1514,2.8428,2.8423v.624H46.9048V2.6748c0-.6118-.0479-1.1636-.72-1.1636-.6954,0-.7437.6358-.7437,1.2232V7.2319c0,.66.144,1.2354.8037,1.2354s.84-.6.84-1.2593V6.0688h-.876V4.81h2.8066V9.8467h-1.415l-.12-.8037a1.5824,1.5824,0,0,1-1.5713.9477C43.8945,9.9907,43.2471,8.6353,43.2471,6.6323Z" />
        <path d="M50.3628.1318h2.6743c1.9551,0,3.2266.5279,3.2266,2.5669,0,1.1992-.2281,1.9908-1.1875,2.4463l1.331,4.7017H54.1885L53.1211,5.4932h-.6118V9.8467H50.3628Zm2.7466,4.126c.8994,0,1.1631-.5039,1.1631-1.3672,0-.8276-.252-1.3193-1.0674-1.3193h-.6958V4.2578Z" />
        <path d="M58.93.1318H61.377l1.811,9.7149H61.209l-.3237-2.0508H59.458l-.3359,2.0508H57.0952Zm1.751,6.3809-.5157-3.79-.5156,3.79Z" />
        <path d="M64.5342,1.7271H63.0708V.1318H68.144V1.7271H66.6929v8.12H64.5342Z" />
        <path d="M68.9985.1318h4.3658V1.5952H71.145V4.0059h1.6914V5.4932H71.145V8.3955h2.2432V9.8467h-4.39Z" />
        <path d="M74.3628.1318h2.4585c2.5308,0,3.2622.9117,3.2622,3.07V6.7285c0,2.1826-.7314,3.1182-3.2383,3.1182H74.3628Zm2.4824,8.2276c.9717,0,1.0318-.42,1.0318-1.2954V2.8306c0-.8516-.0962-1.1993-1.0435-1.1993h-.3242V8.3594Z" />
        <path d="M85.834.1318h2.4468l1.811,9.7149h-1.979l-.3237-2.0508H86.3618l-.3359,2.0508H83.999Zm1.751,6.3809-.5157-3.79-.5156,3.79Z" />
        <path d="M91.0786.1318h3.2622c1.7388,0,2.5547,1.02,2.5547,2.8189,0,2.2427-1.2353,2.77-2.8066,2.77h-.8638V9.8467H91.0786Zm2.8662,4.09c.7915,0,.9356-.42.9356-1.3071,0-.7315-.084-1.2715-.9473-1.2715h-.708V4.2217Z" />
        <path d="M97.9307.1318h3.2622c1.7387,0,2.5547,1.02,2.5547,2.8189,0,2.2427-1.2354,2.77-2.8067,2.77h-.8638V9.8467H97.9307Zm2.8662,4.09c.7915,0,.9355-.42.9355-1.3071,0-.7315-.084-1.2715-.9472-1.2715h-.7081V4.2217Z" />
        <path d="M104.7822.1318h2.6743c1.9551,0,3.2261.5279,3.2261,2.5669,0,1.1992-.2275,1.9908-1.187,2.4463l1.3311,4.7017h-2.2188l-1.0674-4.3535h-.6118V9.8467h-2.1465Zm2.7466,4.126c.8994,0,1.1631-.5039,1.1631-1.3672,0-.8276-.252-1.3193-1.0674-1.3193h-.6958V4.2578Z" />
        <path d="M111.7544,6.7886v-3.61c0-2.0029.7314-3.1782,2.9141-3.1782,2.1948,0,2.9384,1.1753,2.9384,3.1782v3.61c0,2.0029-.7436,3.2021-2.9384,3.2021C112.4858,9.9907,111.7544,8.7915,111.7544,6.7886Zm3.6577.54V2.6509c0-.5762-.0718-1.14-.7436-1.14-.6358,0-.7193.5635-.7193,1.14V7.3281c0,.5518.0479,1.1392.7193,1.1392S115.4121,7.88,115.4121,7.3281Z" />
        <path d="M120.1416.1318h2.4468l1.811,9.7149H122.42l-.3237-2.0508h-1.4273l-.3359,2.0508h-2.0269Zm1.751,6.3809-.5156-3.79-.5157,3.79Z" />
        <path d="M125.0986,6.7524V3.2505c0-2.0151.6953-3.25,2.9141-3.25,2.147,0,2.7944,1.1514,2.7944,2.8066V3.646h-2.0986V2.6748c0-.564-.0122-1.1636-.6958-1.1636s-.7437.6118-.7437,1.1992V7.28c0,.66.108,1.187.7437,1.187.6719,0,.6958-.6.6958-1.187v-1.02h2.0986V7.1c0,1.6553-.6235,2.8906-2.7944,2.8906C125.7939,9.9907,125.0986,8.7192,125.0986,6.7524Z" />
        <path d="M132.022.1318h2.1469V4.0659h1.4991V.1318h2.1591V9.8467H135.668V5.6011h-1.4991V9.8467H132.022Z" />
      </g>
    </svg>
  );
};

const StackedOneIntegrated = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 348.2212 221.667">
      <title>Asset 6</title>
      <g>
        <path d="M.3359,6.7881v-3.61C.3359,1.1748,1.0674,0,3.25,0,5.4453,0,6.1885,1.1748,6.1885,3.1777v3.61C6.1885,8.791,5.4453,9.99,3.25,9.99,1.0674,9.99.3359,8.791.3359,6.7881Zm3.6582.54V2.65c0-.5752-.0722-1.14-.7441-1.14-.6357,0-.7188.5645-.7188,1.14V7.3281c0,.5518.0469,1.1387.7188,1.1387S3.9941,7.88,3.9941,7.3281Z" />
        <path d="M7.5.1318H9.0107L11.002,4.81V.1318h1.7744V9.8467H11.3252L9.334,4.81V9.8467H7.5Z" />
        <path d="M14.2314.1318h4.3663V1.5947h-2.22V4.0059h1.6914V5.4932H16.3779V8.3955h2.2432V9.8467h-4.39Z" />
        <path d="M.48,14.5322H2.627v9.7149H.48Z" />
        <path d="M4.08,14.5322H5.5908L7.582,19.21V14.5322H9.3564v9.7149H7.9053L5.9141,19.21v5.0371H4.08Z" />
        <path d="M11.6875,16.1279H10.2236V14.5322h5.0733v1.5957H13.8457v8.1192H11.6875Z" />
        <path d="M16.1514,14.5322h4.3662v1.4639h-2.22v2.41h1.6914v1.4874H18.2979v2.9023H20.541v1.4512h-4.39Z" />
        <path d="M21.3828,21.0332V17.7705c0-2.0625.6953-3.37,2.9141-3.37,2.1474,0,2.8437,1.1514,2.8437,2.8428v.623h-2.1v-.791c0-.6123-.0478-1.1631-.72-1.1631-.6953,0-.7432.6348-.7432,1.2227v4.498c0,.6592.1436,1.2354.8028,1.2354s.84-.6.84-1.26v-1.14h-.875V19.21h2.8066v5.0371h-1.416l-.1191-.8037a1.5834,1.5834,0,0,1-1.5723.9472C22.0312,24.3906,21.3828,23.0361,21.3828,21.0332Z" />
        <path d="M28.499,14.5322h2.6748c1.9551,0,3.2266.5283,3.2266,2.5664,0,1.2-.2285,1.9912-1.1875,2.4473l1.33,4.7012H32.3242l-1.0664-4.3535h-.6123v4.3535H28.499Zm2.7471,4.126c.8984,0,1.1621-.5039,1.1621-1.3672,0-.8281-.252-1.3193-1.0664-1.3193h-.6963v2.6865Z" />
        <path d="M37.0664,14.5322h2.4473l1.81,9.7149H39.3457l-.3242-2.0508H37.5938l-.336,2.0508H35.2314Zm1.751,6.3809-.5156-3.79-.5157,3.79Z" />
        <path d="M42.67,16.1279H41.207V14.5322h5.0742v1.5957H44.8291v8.1192H42.67Z" />
        <path d="M47.1348,14.5322H51.5v1.4639H49.2812v2.41h1.6915v1.4874H49.2812v2.9023h2.2442v1.4512H47.1348Z" />
        <path d="M52.498,14.5322h2.459c2.5313,0,3.2618.9121,3.2618,3.07v3.5264c0,2.1826-.73,3.1182-3.2383,3.1182H52.498ZM54.9805,22.76c.9726,0,1.0312-.42,1.0312-1.295V17.2305c0-.8516-.0957-1.1993-1.0429-1.1993h-.3243V22.76Z" />
        <path d="M1.835,28.9316H4.2812l1.8116,9.7149h-1.98L3.79,36.5957H2.3633l-.336,2.0508H0Zm1.7509,6.3809-.5156-3.79-.5156,3.79Z" />
        <path d="M7.08,28.9316h3.2617c1.7383,0,2.5547,1.02,2.5547,2.8184,0,2.2432-1.2354,2.77-2.8067,2.77H9.2266v4.126H7.08Zm2.8652,4.09c.792,0,.9356-.42.9356-1.3076,0-.7315-.084-1.27-.9473-1.27h-.707v2.5781Z" />
        <path d="M13.9316,28.9316h3.2618c1.7392,0,2.5546,1.02,2.5546,2.8184,0,2.2432-1.2343,2.77-2.8066,2.77h-.8633v4.126H13.9316Zm2.8663,4.09c.7919,0,.9355-.42.9355-1.3076,0-.7315-.084-1.27-.9473-1.27h-.708v2.5781Z" />
        <path d="M20.7832,28.9316H23.458c1.9551,0,3.2256.5284,3.2256,2.5664,0,1.1993-.2266,1.9913-1.1865,2.4473l1.331,4.7012H24.6094L23.542,34.293H22.93v4.3535H20.7832Zm2.7471,4.126c.8994,0,1.1631-.5039,1.1631-1.3672,0-.8281-.252-1.3193-1.0674-1.3193H22.93v2.6865Z" />
        <path d="M27.7559,35.5879V31.9785c0-2.0029.7314-3.1787,2.914-3.1787,2.1953,0,2.9385,1.1758,2.9385,3.1787v3.6094c0,2.0029-.7432,3.2021-2.9385,3.2021C28.4873,38.79,27.7559,37.5908,27.7559,35.5879Zm3.6582.54V31.45c0-.5752-.0723-1.1387-.7442-1.1387-.6357,0-.7187.5635-.7187,1.1387v4.6777c0,.5518.0468,1.14.7187,1.14S31.4141,36.68,31.4141,36.1279Z" />
        <path d="M36.1426,28.9316H38.59L40.4,38.6465H38.4219l-.3242-2.0508H36.6709l-.3359,2.0508H34.3086Zm1.7519,6.3809-.5156-3.79-.5156,3.79Z" />
        <path d="M41.1,35.5518V32.05c0-2.0146.6953-3.25,2.9141-3.25,2.1465,0,2.7949,1.1514,2.7949,2.8066v.84H44.71v-.9717c0-.5635-.0127-1.1631-.6963-1.1631s-.7432.6114-.7432,1.1992V36.08c0,.6592.1084,1.1875.7432,1.1875.6718,0,.6963-.6.6963-1.1875v-1.02h2.0986v.84c0,1.6543-.6231,2.89-2.7949,2.89C41.7949,38.79,41.1,37.52,41.1,35.5518Z" />
        <path d="M48.0234,28.9316H50.17v3.9336h1.5V28.9316h2.1582v9.7149H51.67V34.4h-1.5v4.2461H48.0234Z" />
      </g>
    </svg>
  );
};

const StackedElementIntegrated = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 348.2212 221.667">
      <title>Asset 5</title>
      <g>
        <path d="M9.4492,10.3008H0V0H32.7627V10.3008H23.3911V62.7373H9.4492Z" />
        <path d="M38.4927,0H52.3569V25.4043h9.6817V0H75.9805V62.7373H62.0386V35.3184H52.3569V62.7373H38.4927Z" />
        <path d="M85.5044,0h28.1934V9.4492H99.3687V25.0176H110.29v9.6045H99.3687V53.3652h14.4838v9.3721H85.5044Z" />

        <path d="M3.7954,79H31.9883v9.4492H17.6592v15.5684H28.5806v9.6045H17.6592v18.7431H32.1431v9.3721H3.7954Z" />
        <path d="M38.4155,79H52.2793v53.3652H66.686v9.3721H38.4155Z" />
        <path d="M38.4155,79H52.2793v53.3652H66.686v9.3721H38.4155Z" />
        <path d="M72.7251,79h28.1934v9.4492H86.5894v15.5684H97.51v9.6045H86.5894v18.7431h14.4838v9.3721H72.7251Z" />
        <path d="M72.7251,79h28.1934v9.4492H86.5894v15.5684H97.51v9.6045H86.5894v18.7431h14.4838v9.3721H72.7251Z" />
        <path d="M108.1973,79h14.8711l6.8935,36.4805L137.3975,79h14.3291l1.0839,62.7373h-9.8364L141.8125,99.99l-7.2808,41.7471h-8.7524L118.0342,99.68l-.93,42.0576h-9.9912Z" />
        <path d="M161.8706,79H190.064v9.4492H175.7349v15.5684h10.9209v9.6045H175.7349v18.7431h14.4839v9.3721H161.8706Z" />
        <path d="M196.4907,79H206.25l12.8574,30.207V79H230.57v62.7373h-9.3716l-12.8574-32.53v32.53h-11.85Z" />
        <path d="M245.5942,89.3008H236.145V79h32.7627V89.3008h-9.3716v52.4365H245.5942Z" />
        <path d="M245.5942,89.3008H236.145V79h32.7627V89.3008h-9.3716v52.4365H245.5942Z" />

        <path d="M12.625,158h15.8l11.6958,62.7373h-12.78L25.25,207.4932H16.0327l-2.1684,13.2441H.7744Zm11.3081,41.2051L20.6025,174.73,17.272,199.2051Z" />
        <path d="M46.47,158H67.5376c11.231,0,16.498,6.583,16.498,18.2012,0,14.4843-7.978,17.8916-18.1245,17.8916H60.3345v26.6445H46.47Zm18.5117,26.4111c5.1119,0,6.0415-2.7109,6.0415-8.4423,0-4.7247-.5424-8.21-6.1191-8.21h-4.57v16.6523Z" />
        <path d="M90.6948,158h21.0674c11.231,0,16.4981,6.583,16.4981,18.2012,0,14.4843-7.9781,17.8916-18.1246,17.8916h-5.5766v26.6445H90.6948Zm18.5117,26.4111c5.1119,0,6.0415-2.7109,6.0415-8.4423,0-4.7247-.5424-8.21-6.1191-8.21h-4.57v16.6523Z" />
        <path d="M134.9194,158h17.2725c12.625,0,20.835,3.4082,20.835,16.5752,0,7.7451-1.4717,12.8574-7.668,15.8008l8.5976,30.3613H159.6274l-6.8935-28.1152h-3.95v28.1152H134.9194Zm17.7373,26.6436c5.8086,0,7.5127-3.253,7.5127-8.8291,0-5.3448-1.6264-8.5206-6.893-8.5206h-4.4927v17.35Z" />
        <path d="M179.9185,200.9863V177.6729c0-12.9346,4.7246-20.5254,18.8212-20.5254,14.1739,0,18.9761,7.5908,18.9761,20.5254v23.3134c0,12.9346-4.8022,20.6807-18.9761,20.6807C184.6431,221.667,179.9185,213.9209,179.9185,200.9863Zm23.623,3.4854V174.2646c0-3.7167-.4648-7.3574-4.8018-7.3574-4.1054,0-4.6474,3.6407-4.6474,7.3574v30.2071c0,3.5635.31,7.3584,4.6474,7.3584S203.5415,208.0352,203.5415,204.4717Z" />
        <path d="M234.0576,158h15.8l11.6958,62.7373h-12.78l-2.0913-13.2441h-9.2173l-2.1684,13.2441h-13.09Zm11.3081,41.2051L242.0352,174.73l-3.3306,24.4756Z" />
        <path d="M266.0454,200.7539V178.1377c0-13.0117,4.4922-20.99,18.8213-20.99,13.8638,0,18.0464,7.4355,18.0464,18.125v5.4209H289.3589V174.42c0-3.6406-.0777-7.5127-4.4922-7.5127s-4.8022,3.95-4.8022,7.7451v29.51c0,4.26.6967,7.668,4.8022,7.668,4.3369,0,4.4922-3.8731,4.4922-7.668v-6.583h13.5542V203c0,10.69-4.0274,18.667-18.0464,18.667C270.5376,221.667,266.0454,213.457,266.0454,200.7539Z" />
        <path d="M310.7334,158h13.8647v25.4043H334.28V158h13.9414v62.7373H334.28V193.3184h-9.6817v27.4189H310.7334Z" />
      </g>
    </svg>
  );
};

class Checked extends React.Component {
  render() {
    return (
      <svg width="24" fill="#00ea00" height="24" viewBox="0 0 24 24">
        <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
      </svg>
    );
  }
}

class CheckBox extends React.Component {
  render() {
    return (
      <svg width="24" height="24" fill="#666666" viewBox="0 0 24 24">
        <path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z" />
      </svg>
    );
  }
}

const easeInCubic = function (t) {
  return t * t * t;
};

const AnimatedStyledHexGroup = a(StyledHexGroup);

const PageTemplate = ({ className, fields }) => {
  const videoRef = useRef();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    //TODO: Some sort of fallback option if Hls is NOT supported?
    if (Hls.isSupported()) {
      var video = videoRef.current;
      var hls = new Hls();
      // bind them together
      hls.attachMedia(video);
      hls.on(Hls.Events.MEDIA_ATTACHED, function () {
        hls.loadSource('/videos/whatvideo/video-v20191009-01.m3u8');
      });
    }
    const loadTime = window.performance.now();
    function videoProgress() {
      if (videoRef.current) {
        const percent = (videoRef.current.currentTime / videoRef.current.duration) * 100;
        // console.log(`video progress ${percent}%`);
        if (percent > 96) {
          videoRef.current.currentTime = 14.12;
        }
        if (window.performance.now() - loadTime > 60000) {
          videoRef.current.removeEventListener('timeupdate', videoProgress);
        }
      }
    }
    if (videoRef.current) {
      videoRef.current.addEventListener('timeupdate', videoProgress);
    }
    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener('timeupdate', videoProgress);
      }
    };
  }, [isMobile, videoRef]);

  const [{ hexOpacity }, setHexOpacity] = useSpring(() => ({ hexOpacity: 1 }));
  const [animComplete, setAnimComplete] = useState({ val: false, pos: 0 });
  const [bottomLine, setBottomLineScale] = useSpring(() => ({ transform: 'scaleX(0)' }));
  const [windowDimensions, setDimensions] = useState({ vw: 1200, vh: 850 });
  const [dispatch] = useScrollAnimation();

  const lineMove = (item) => {
    if (typeof item.scrollPct !== 'undefined') {
      setBottomLineScale.start({ transform: 'scaleX(' + interpolateAndClamp([0, 1], [0, 210], item.scrollPct) + ')' });
    }
  };

  const hexOp1 = (item) => {
    if (typeof item.scrollPct !== 'undefined') {
      setHexOpacity.start({ hexOpacity: interpolateAndClamp([0, 1], [1, 0.35], item.scrollPct) });
    }
  };

  const hexOp2 = (item) => {
    if (typeof item.scrollPct !== 'undefined') {
      setHexOpacity.start({ hexOpacity: interpolateAndClamp([0, 1], [0.35, 0], item.scrollPct) });
    }
  };

  const complete = (item) => {
    if (typeof (item.isComplete !== 'undefined')) {
      const rect = { top: window.innerHeight / 2 - 215 };
      setAnimComplete({ val: item.isComplete, pos: window.innerHeight * 3.751 + rect.top });
    }
  };

  useEffect(() => {
    //set an initial value
    if (!isMobile) {
      dispatch({
        type: 'registerItem',
        start: 10,
        end: 700,
        id: 'bottom-line-scale',
        onUpdate: lineMove,
      });
      dispatch({
        type: 'registerItem',
        start: 700,
        end: 1400,
        id: 'hex-fade1',
        onUpdate: hexOp1,
      });
      dispatch({
        type: 'registerItem',
        start: 2100,
        end: 2200,
        id: 'hex-fade2',
        onUpdate: hexOp2,
      });
      dispatch({
        type: 'registerItem',
        start: 3749,
        end: 3750,
        id: 'hex-abs',
        onUpdate: complete,
      });
    }
    //this will break the dispatch updates
    // return () => {
    //   dispatch({type: 'removeItem', id: 'bottom-line-scale'});
    //   dispatch({type: 'removeItem', id: 'hex-fade1'});
    //   dispatch({type: 'removeItem', id: 'hex-fade2'});
    //   dispatch({type: 'removeItem', id: 'hex-abs'});
    // };
  }, [isMobile, dispatch]);

  useEffect(() => {
    function adjustHeight() {
      const vh = window.innerHeight;
      const vw = window.innerWidth;
      setIsMobile((value) => {
        // console.log({ value, vw });
        if (vw <= 900) return true;
        else return false;
      });
      setDimensions({ vw: vw, vh: vh });
      dispatch({
        type: 'updateItem',
        start: vh * 0.7,
        end: vh * 1.4,
        id: 'hex-fade1',
      });
      dispatch({
        type: 'updateItem',
        start: vh * 2.1,
        end: vh * 2.2,
        id: 'hex-fade2',
      });
      dispatch({
        type: 'updateItem',
        start: vh * 3.75,
        end: vh * 3.751,
        id: 'hex-abs',
      });
    }
    window.addEventListener('resize', adjustHeight);
    adjustHeight();
    return () => {
      window.removeEventListener('resize', adjustHeight);
    };
  }, []);

  // const item = increment % 2 === 1 ? <StackedOneIntegrated key="second" /> : <StackedElementIntegrated key="first" />;

  // const item2 = increment % 2 === 1 ? <Checked key="checked" /> : <CheckBox key="checkbox" />;
  return (
    <StyledContainer>
      <div className="introPanel">
        <div className="flexCenter">
          <div className="thesvg">
            <StackedElementIntegrated key="first" />
          </div>
        </div>
        <div className="flexCenter">
          <div className="what-we-do">
            WHAT
            <br />
            WE
            <br />
            DO
          </div>
        </div>
        {isMobile && (
          <GatsbyImage image={fields.mobile_intro_image.childImageSharp.gatsbyImageData} alt="intro background" />
        )}
        {!isMobile && (
          <>
            <video ref={videoRef} className="homevideo" muted="muted" autoPlay="autoplay" />
            <div style={{ width: '100%', height: '3px' }}>
              <a.div className="bottom-line" style={bottomLine} />
            </div>
          </>
        )}
      </div>
      {!isMobile && (
        <>
          <a.div
            className="hex-group-container"
            style={{
              opacity: hexOpacity,
              position: 'fixed',
            }}
          >
            <Hexes
              animProgress={99} //animProgress controls e.g. movement of main hexes, opacity of other hexes, reveal/hide of service labels, enable/disable spinner animation
              lightningPos={0}
            />
          </a.div>
          <AnimatedStyledHexGroup
            style={{
              position: animComplete.val ? 'absolute' : 'fixed',
              top: animComplete.val ? animComplete.pos + 'px' : 'calc(50% - 430px / 2)',
            }}
          >
            <AnimHexgroup vh={windowDimensions.vh} vw={windowDimensions.vw} whatpage={true} />
          </AnimatedStyledHexGroup>
        </>
      )}
      <div className="slide slide-1">
        <div className="message" dangerouslySetInnerHTML={{ __html: fields.slide1_message }} />
      </div>
      {isMobile && <GatsbyImage image={fields.mobile_img_1.childImageSharp.gatsbyImageData} alt="slide 1" />}
      <div className="slide">
        <div className="message" dangerouslySetInnerHTML={{ __html: fields.slide2_message }} />
      </div>
      {isMobile && <GatsbyImage image={fields.mobile_img_2.childImageSharp.gatsbyImageData} alt="slide 1" />}
      <div className="slide">
        <div className="message" dangerouslySetInnerHTML={{ __html: fields.slide3_message + fields.slide4_message }} />
      </div>
      <div className="slide4">
        <h2>Our Services</h2>
        <div className="service-grid">
          {fields.services.items.map((item, idx) => (
            <div className={`div${idx + 1} item`} key={idx}>
              {item.link ? (
                <Link to={item.link}>
                  <GatsbyImage image={item.img.childImageSharp.gatsbyImageData} alt={item.alt} />

                  <div className="label">{item.label}</div>
                </Link>
              ) : (
                <>
                  <GatsbyImage image={item.img.childImageSharp.gatsbyImageData} alt={item.alt} />

                  <div className="label">{item.label}</div>
                </>
              )}
            </div>
          ))}
        </div>
      </div>
    </StyledContainer>
  );
};

const Page = ({ data }) => {
  if (data.allFile.edges[0].node.children[0].name) {
    const page = data.allFile.edges[0].node.children[0];
    const meta = data.site.siteMetadata;

    return (
      <Layout>
        <Helmet>
          <title>{page.meta_title}</title>
          <meta name="description" content={page.meta_desc} />
          <meta property="og:title" content={page.meta_title} />
          <meta property="og:type" content="website" />
          <meta property="og:description" content={page.meta_desc} />
          {page.meta_ogimg && <meta property="og:image" content={meta.siteUrl + page.meta_ogimg.publicURL} />}
          <meta property="og:url" content={meta.siteUrl + '/' + page.name + '/'} />
        </Helmet>
        <PageTemplate className="what-page" fields={page} />
      </Layout>
    );
  } else {
    return <></>;
  }
};

export default Page;

export const pageQuery = graphql`
  query WhatPageById($id: String!) {
    allFile(filter: { id: { eq: $id } }) {
      edges {
        node {
          relativePath
          absolutePath
          dir
          name
          id
          children {
            ... on IntegratedMarketingServicesHJson {
              name
              meta_title
              meta_desc
              meta_ogimg {
                publicURL
              }
              title1
              background {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, width: 1920, quality: 93)
                }
              }
              slide1_message
              slide2_message
              slide3_message
              slide4_message
              slide4_cta_text
              slide4_cta_link
              mobile_intro_image {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, quality: 85)
                }
              }
              mobile_intro_alt
              mobile_img_1 {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, quality: 85)
                }
              }
              mobile_img_1_alt
              mobile_img_2 {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, quality: 85)
                }
              }
              mobile_img_2_alt
              services {
                items {
                  label
                  link
                  img {
                    childImageSharp {
                      gatsbyImageData(layout: FULL_WIDTH, quality: 85)
                    }
                  }
                  alt
                }
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
